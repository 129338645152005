import React from 'react'
import Slider from 'react-slick'
import CategoryCard from '../../common/CategoryCard';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import SampleNextArrow from '../../common/SampleNextArrow';
import SamplePrevArrow from '../../common/SamplePrevArrow';
import { useNavigate } from 'react-router-dom';

var settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3
        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const categories = ['BIJILI','BOMB','EDC-SERIES','FANCY','FLOWER POTS','FOUNTAINS','COMBOS','GROUND CHAKKARS','LAKSHMI CRACKERS','REPEATING SHOTS','ROCKET','SPARKLERS','TWINKLING STARS & CANDLES','K-SERIES']

  const CustomCardHeader = styled(CardHeader)(({ theme }) => ({
    borderBottom: "1px solid #dee2e3",
    backgroundColor:'#074173',
    color: '#fff'
  }));

export default function BannerCategories({title,cardClass,headClass,vClass,path}) {
  const navigate = useNavigate();
  const handleView = () => {
    navigate(path);
  }
  return (
    <Box>
      <Paper elevation={2}>
        <Card >
          <CustomCardHeader     
            action={
              <Button variant="outlined" startIcon={<VisibilityIcon />} size="small" className="view-all" onClick={handleView}>
              View All
            </Button>
            }
            title={title}
            className="banner-card-header"
          />
          <CardContent>
            <div className="slider-block">
              <Slider {...settings}>
                {categories.map((ele,index) => 
                <div className='' key={`cat_${index}`}>
                  <CategoryCard title={ele} image={`${process.env.PUBLIC_URL}/images/category/${ele}.png`}/>
                </div>
                )}      
              </Slider>
              </div>
          </CardContent>     
        </Card>
      </Paper>
    </Box>     
  )
}
