import React from 'react'
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: "2px solid #42a6fc"
  }));

export default function FeatureCard({logo,title,desc}) {
  return (
    <Item>
        <span className={`${logo} whycm-logo`}></span>
        <h4><b>{title}</b></h4>
        <small>{desc}</small>
    </Item>
  )
}
