import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import StickyNavBar from './layout/StickyNavBar';
import { useState } from 'react';
import AuthenticationModal from '../common/modals/AuthenticationModal';
import SearchModal from '../common/modals/SearchModal';
import {Outlet, useNavigate} from 'react-router-dom';
import { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { CUSTOMER_ACCOUNT, CUSTOMER_CART, CUSTOMER_COMBOS, CUSTOMER_INITIAL, CUSTOMER_NEW_ARRIVALS, CUSTOMER_PAYMENT, CUSTOMER_SHIPPING_POLICY, CUSTOMER_SHOP } from '../../helpers/constants/RoutePaths';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {Link} from 'react-router-dom';
import Footer from './layout/Footer';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import BrightnessLowIcon from '@mui/icons-material/BrightnessLow';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { dialogToggle } from '../../redux/actions/reducers/AuthenticationDialogReducer';
import { setAuthFlag } from '../../redux/actions/reducers/AuthenticateCustomerReducer';
import { getCartItems } from '../../redux/actions';
import { authCustomer,showToast } from '../../helpers/functions/CustomFunctions';
import LogoutIcon from '@mui/icons-material/Logout';
import { AUTH_CUSTOMER } from './../../helpers/constants/LocalStorage';
const theme = createTheme();

const mainMenues = [
  {title: "Home", link: CUSTOMER_INITIAL, icon: <HomeIcon/>},
  {title: "Shop", link: CUSTOMER_SHOP, icon: <ShoppingBagIcon/>},
  {title: "New Arrivals", link: CUSTOMER_NEW_ARRIVALS, icon: <FiberNewIcon/>},
  {title: "Combos", link: CUSTOMER_COMBOS, icon: <BrightnessLowIcon/>},
  {title: "Payment Details", link: CUSTOMER_PAYMENT, icon: <CurrencyRupeeIcon/>},
  {title: "Shipping Policy", link: CUSTOMER_SHIPPING_POLICY, icon: <LocalShippingIcon/>}
];
const mobileMainMenues = [
  {title: "My Account", link: "account", icon: <AccountCircleIcon/>},
  {title: "My Cart", link: CUSTOMER_CART, icon: <ShoppingCartIcon/>},
  {title: "Log Out", link: 'logout', icon: <LogoutIcon/>},
];

export default function CustomerLayout() {
  const {flag} = useSelector((state) => state.authDialog);
  const {flag:authFlag} = useSelector((state) => state.authCustomer);
  const [searchDialogFlag, setSearchDialogFlag] = useState(false);
  const [visible, setVisible] = useState(false);
  const [drawerFlag,setDrawerFlag] = useState(false);
  const navigate = useNavigate();
  const dispatch  = useDispatch();
  const toggleDrawer = () => {
    setDrawerFlag(!drawerFlag);
  }
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };

  const handleDialogFlag = () => {
    dispatch(dialogToggle());
  };

  const handleSearchToggleFlag = () => {
    setSearchDialogFlag(!searchDialogFlag);
  };

  const handleMenuClick = (link) => {
    if(link === 'account'){
      if(authCustomer()){
        navigate(CUSTOMER_ACCOUNT);
      }else{
        toggleDrawer();
        handleDialogFlag(); 
      }
    }else if(link === 'logout'){
      localStorage.removeItem(AUTH_CUSTOMER);   
      dispatch(setAuthFlag(false))   
      showToast('You have successfully logged out!','success');
      navigate(CUSTOMER_INITIAL);
    }
    else{
      navigate(link);
    }
  }

  const handleScroll = () => {
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'      
    });
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    const customer = authCustomer();
    if(customer){
      dispatch(getCartItems());
      dispatch(setAuthFlag(true))
    }
  },[]);

  const {items = []} = useSelector((state) => state.cartItems);
  let cartTotalPrice = 0;
  cartTotalPrice = items.reduce((totalAmount,ele) => totalAmount = totalAmount + parseFloat(ele.product_bill),0)
  const dynamicMobMenus= authFlag ? mobileMainMenues : mobileMainMenues.filter((ele) => ele.link !== 'logout');
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />    
      {flag && 
        <AuthenticationModal handleDialogFlag={handleDialogFlag} dialogFlag={flag}/> 
      }
      {searchDialogFlag && 
      <SearchModal handleSearchToggleFlag={handleSearchToggleFlag} searchDialogFlag={searchDialogFlag}/>}
      <StickyNavBar handleDialogFlag={handleDialogFlag} handleSearchToggleFlag={handleSearchToggleFlag} toggleDrawer={toggleDrawer} mainMenues={mainMenues}/>
      <Drawer
            anchor={'left'}
            open={drawerFlag}
            onClose={toggleDrawer}
          >
            <Box
              sx={{ width: 230 }}
              role="presentation"
              onClick={toggleDrawer}
              onKeyDown={toggleDrawer}
            >
              <div id="mob-menu-head">
                <img src="/images/logowithtext.png" alt="Crackers Mart" width={100} />
              </div>
              <Divider />
              <List>
                {mainMenues.map(({title, icon, link}, index) => (
                  <ListItem key={`menu_side_${index}`} disablePadding>
                    <ListItemButton onClick={() => handleMenuClick(link)}>
                      <ListItemIcon>
                         {icon}
                      </ListItemIcon>
                      <ListItemText primary={title} />
                    </ListItemButton>
                  </ListItem>
                ))}
                {dynamicMobMenus.map(({title, icon, link}, index) => (
                  <ListItem key={`additional_side_${index}`} disablePadding>
                    <ListItemButton onClick={() => handleMenuClick(link)}>
                      <ListItemIcon>
                         {icon}
                      </ListItemIcon>
                      <ListItemText primary={title} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
        </Drawer>
      <div>   
      <Outlet />
      </div>      
      
      <Footer handleDialogFlag={handleDialogFlag}/>

      {visible &&
          <IconButton aria-label="delete" size="large" id="page-to-top" onClick={handleScroll} className="shadow-effect">
            <ExpandLessIcon fontSize="inherit" />
          </IconButton>
        }

      <div id="side-info" className="shadow-effect">
          <div id="whatsapp">
          <a href="https://wa.me/918098667575?text=Welcome%20to%20Sri%20Kathik%20Pyro%20Park%20%2C%20the%20Best%20place%20to%20buy%20crackers%20in%20INDIA.%20" target="_blank" rel="noreferrer" className="no-text-decoration">
            <WhatsAppIcon />
          </a>
          </div>
          <div id="cart">
            <Link to={CUSTOMER_CART}>
            <ShoppingCartIcon />
            <h2>&#8377; {cartTotalPrice}</h2>
            </Link>
          </div>
      </div>
      
    </ThemeProvider>
  );
}