import axios from "axios";

const AxiIns = axios.create({
    baseURL: 'https://karthipyro.skcrackers.co.in/',
    timeout: 1000,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin' : '*'
    }
  });
export default AxiIns;