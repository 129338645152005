import React from 'react'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { UPI_ID, WHATS_APP_NUMBER } from '../../helpers/constants/AppValues';
import {  Grid, } from '@mui/material'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import "./Common.scss";
import "./PaymentPolicy.scss";

const CustomAlert = styled(Alert)(({ theme }) => ({
    borderRadius: '10px !important', 
    textAlign:'center',    
    width: '100% !important',
    '& .MuiAlert-message': {
        width: '100% !important'
    }
    
}));
export default function PaymentPolicy() {
  return (
    <div className='dynamic-content'>  
         <Container maxWidth="lg">
            <Box sx={{ bgcolor: '#fff', padding: '5rem 1rem 1rem 1rem' }}>
                <center><h4><b>PAYMENT DETAILS</b></h4></center>
                <CustomAlert icon={false} severity="info" >
                    <div className='ship-info'>
                        <div className='main-sec'>
                            <label>FOR DELIVERY &amp; SHIPPING STATUS</label>
                            <p><b>www.karthekarthe5@gmail.com</b></p>
                        </div>
                        <div className='main-sec'>
                            <label>For Helpdesk</label>
                            <div className='whatsapp-chip'>
                                <a href="https://wa.me/918098667575" target="_blank" rel="noreferrer" className="no-text-decoration">
                                <Chip icon={<WhatsAppIcon />} label={WHATS_APP_NUMBER} />
                                </a>
                            </div>
                        </div>
                        <div className='main-sec'>
                            <label>UPI ID</label>
                            <p><b>{UPI_ID}</b></p>
                        </div>
                    </div>
                </CustomAlert>
                <p className='payment-desc'>Payments can be done using <b>NEFT, RTGS, GPay, PhonePe</b> to the below details. Cash On Delivery not available. Once the order is made using Phone or Web or App, You can visit the orders in your profile page. Once fund transfer is made using above methods send the detail using Whatsapp to below helpdesk number.</p>                    
                <Grid container spacing={2}
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-start">
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <div>
                           <center><h4>Account Details</h4></center>
                           <div className='bank-acc-card'>
                                <div id="bank">
                                    <AccountBalanceIcon />
                                    <h4>Axis Bank</h4>
                                </div>
                                <div id='info'>
                                    <h3>KARTHEESWARAN R</h3>
                                    <h2>9230 2000 3545 252</h2>
                                    <div className='between-space'>
                                        <p><b>UTIB0002760</b></p>
                                        <p><b>SIVAKASI</b></p>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <div>
                           <center><h4>GPay</h4></center>
                           <div className='center-text gpay-card'>
                            <center><img src="/images/Google-Pay-Logo.png" alt="GPay" width={100}/></center>
                            <h2>85 26 56 34 03</h2>
                            <h2>70 10 94 92 04</h2>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Container>   
    </div>
  )
}
