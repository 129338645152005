export const UPI_ID = '8526563403';
export const WHATS_APP_NUMBER = "80 98 66 75 75";
export const MINIMUM_ORDER_VALUE="1500";
export const PACKAGING_CHARGE_PERCENTAGE = 3;
export const REVIEWS = [{
    name:'Vaira',
    review : 5,
    msg:"Crackers are good at reasonable price."
},{
    name:'SARAVANAN',
    review: 5,
    msg: "Good."
},{
    name:'Sanjay',
    review: 5,
    msg: "reasonable price",
},{
    name:'Manoj',
    review: 5,
    msg: "Crackers are so good. Lot of varieties.. very unique products.  We are very much satisfied."
},];