import React from 'react'
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { styled } from '@mui/material/styles';

const CustomHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: '#074173 !important', 
    '& .MuiTableCell-head': {
        color: '#fff !important',
    }
}));
export default function CustomTableHeader({columns = []}) {
  return (
    <CustomHead>
        <TableRow>
        {columns.map((col,index) => 
        <TableCell key={`col_${index}`}>{col}</TableCell>
        )}
        </TableRow>
    </CustomHead>
  )
}
